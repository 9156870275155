// NPM
import * as React from "react"
import _ from 'lodash'

// GraphQL
import { graphql } from 'gatsby';

// Components
import Layout from "src/layouts/en"
import Home from 'src/components/pages/Home/Home'

const IndexPageEn = ({ data, location }) => {
  const topNav = data.topNav
  const sections = _.get(data, `indexPage.sections`, [])
  const testimonials = _.get(data, `testimonials.nodes`, [])
  const bottomNav = data.bottomNav
  const socialMedia = _.get(data, `socialMedia.nodes`, [])

  return (
    <Layout 
      location={ location }
      topNav={ topNav }
      page={ data.indexPage }
      bottomNav={ bottomNav }
      socialMedia={ socialMedia }>
        <Home 
          sections={ sections } 
          testimonials={ testimonials }
        />
    </Layout>
  )
}

export const query = graphql`  
  query IndexPageEn {
    topNav: strapiTopNav(locale: {eq: "en"}) {
      ...topNavContent
    }

    indexPage: strapiPage(locale: {eq: "en"}, uuid: {eq: "home"}) {
      ...pageInformation
    }

    testimonials: allStrapiTestimonial(filter: {locale: {eq: "en"}}) {
      nodes {
        name
        quote
        credit
        order
      }
    }

    bottomNav: strapiBottomNav(locale: {eq: "en"}) {
      ...bottomNavContent
    }

    socialMedia: allStrapiSocialMedia(filter: {locale: {eq: "en"}}) {
      nodes {
        ...socialMedia
      }
    }
  }
`

export default IndexPageEn